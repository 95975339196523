<template>
	<b-container fluid>
		<b-row>
			<b-col lg="4">
				<iq-card class-name="iq-user-profile-block1">
					<template v-slot:body>
						<div class="user-details-block">
							<div class="user-profile text-center">
								<img
									:src="data.photo"
									alt="profile-img"
									class="avatar-130 img-fluid"
									style="object-fit: cover; border-radius: 50%"
								/>
							</div>
							<div class="text-center mt-3">
								<h4>
									<b>
										{{ user.name }} {{ user.first_last_name }}
										{{ user.second_last_name }}
									</b>
								</h4>
								<p>{{ user.gender }}</p>
							</div>
							<ul
								class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0"
							>
								<li class="text-center">
									<h6 class="text-primary">Peso (kg)</h6>
									<h3>{{ data?.extra_data?.weight ?? 0 }}<span>kg</span></h3>
								</li>
								<li class="text-center">
									<h6 class="text-primary">Altura</h6>
									<h3>{{ data?.extra_data?.height ?? 0 }}<span>cm</span></h3>
								</li>
								<li class="text-center">
									<h6 class="text-primary">Edad</h6>
									<h3 class="text-warning">{{ data?.age ?? 0 }}</h3>
								</li>
							</ul>
						</div>
					</template>
				</iq-card>
				<!-- <iq-card body-class="patient-steps">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <b-col md="6">
                <div class="data-block">
                  <p class="mb-0">Walked</p>
                  <h5>4532 steps</h5>
                </div>
                <div class="data-block mt-3">
                  <p class="mb-0">My Goal</p>
                  <h5>6500 steps</h5>
                </div>
              </b-col>
              <b-col md="6">
                <div class="progress-round patient-progress mx-auto" data-value="80">
                  <span class="progress-left">
                    <span class="progress-bar border-secondary"></span>
                  </span>
                  <span class="progress-right">
                    <span class="progress-bar border-secondary"></span>
                  </span>
                  <div
                    class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center text-center">
                    <div class="h4 mb-0">
                      4532<br />
                      <span class="font-size-14">left</span>
                    </div>
                  </div>
                </div>
              </b-col>
            </div>
            <ul class="patient-role list-inline d-flex align-items-center p-0 mt-4 mb-0">
              <li class="text-left">
                <h6 class="text-primary">Carbs</h6>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <div class="iq-progress-bar">
                    <span class="bg-primary" data-percent="85"></span>
                  </div>
                </div>
              </li>
              <li class="text-left">
                <h6 class="text-primary">Protein</h6>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <div class="iq-progress-bar">
                    <span class="bg-danger" data-percent="65"></span>
                  </div>
                </div>
              </li>
              <li class="text-left">
                <h6 class="text-primary">Fat</h6>
                <div class="iq-progress-bar-linear d-inline-block w-100">
                  <div class="iq-progress-bar">
                    <span class="bg-info" data-percent="70"></span>
                  </div>
                </div>
              </li>
            </ul>
            <hr />
            <div class="patient-steps2">
              <div class="d-flex align-items-center justify-content-between">
                <b-col md="6">
                  <div class="data-block">
                    <p class="mb-0">Burned</p>
                    <h5>325 kcal</h5>
                  </div>
                  <div class="data-block mt-3">
                    <p class="mb-0">My Goal</p>
                    <h5>800 kcal</h5>
                  </div>
                </b-col>
                <b-col md="6">
                  <div class="progress-round patient-progress mx-auto" data-value="60">
                    <span class="progress-left">
                      <span class="progress-bar border-secondary"></span>
                    </span>
                    <span class="progress-right">
                      <span class="progress-bar border-secondary"></span>
                    </span>
                    <div
                      class="progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center text-center">
                      <div class="h4 mb-0 text-warning">
                        325<br />
                        <span class="font-size-14 text-secondary">left</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </div>
              <ul class="patient-role list-inline d-flex align-items-center p-0 mt-4 mb-0">
                <li class="text-left">
                  <h6 class="text-primary">Carbs</h6>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <div class="iq-progress-bar">
                      <span class="bg-primary" data-percent="50"></span>
                    </div>
                  </div>
                </li>
                <li class="text-left">
                  <h6 class="text-primary">Protein</h6>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <div class="iq-progress-bar">
                      <span class="bg-danger" data-percent="60"></span>
                    </div>
                  </div>
                </li>
                <li class="text-left">
                  <h6 class="text-primary">Fat</h6>
                  <div class="iq-progress-bar-linear d-inline-block w-100">
                    <div class="iq-progress-bar">
                      <span class="bg-info" data-percent="70"></span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </template>
        </iq-card> -->
			</b-col>
			<b-col lg="8">
				<iq-card body-class="pb-0">
					<template v-slot:body>
						<b-row>
							<b-col sm="12">
								<div class="iq-card">
									<div class="iq-header-title">
										<h4 class="card-title text-primary">Información Personal</h4>
									</div>
									<div class="iq-card-body pl-0 pr-0 pb-0">
										<b-row>
											<b-col md="6">
												<div class="training-block d-flex align-items-center">
													<div class="rounded-circle iq-card-icon iq-bg-primary">
														<img
															src="../../../assets/images/page-img/34.png"
															class="img-fluid"
															alt="icon"
														/>
													</div>
													<div class="ml-3">
														<h5>Tipo de Sangre</h5>
														<p class="mb-0">{{ data?.extra_data?.blood_type ?? 0 }}</p>
													</div>
												</div>
											</b-col>
											<b-col md="6">
												<div class="training-block d-flex align-items-center">
													<div class="rounded-circle iq-card-icon iq-bg-primary">
														<img
															src="../../../assets/images/page-img/35.png"
															class="img-fluid"
															alt="icon"
														/>
													</div>
													<div class="ml-3">
														<h5>Presión Arterial</h5>
														<p
															class="mb-0"
															v-if="
																data?.extra_data?.systolic_pressure < 120 ||
																data?.extra_data?.diastolic_pressure < 80
															"
														>
															<mark style="background-color: #52be80">
																Sistólica:
																{{ data?.extra_data?.systolic_pressure ?? 0 }}
															</mark>
															<br />
															<mark style="background-color: #52be80">
																Diastólica:
																{{ data?.extra_data?.diastolic_pressure ?? 0 }}
															</mark>
														</p>
														<p
															class="mb-0"
															v-else-if="
																data?.extra_data?.systolic_pressure >= 120 &&
																data?.extra_data?.systolic_pressure < 139 &&
																data?.extra_data?.diastolic_pressure >= 80 &&
																data?.extra_data?.diastolic_pressure < 89
															"
														>
															<mark style="background-color: #ecb563">
																Sistólica:
																{{ data?.extra_data?.systolic_pressure ?? 0 }}
															</mark>
															<br />
															<mark style="background-color: #ecb563">
																Diastólica:
																{{ data?.extra_data?.diastolic_pressure ?? 0 }}
															</mark>
														</p>
														<p
															class="mb-0"
															v-else-if="
																data?.extra_data?.systolic_pressure >= 140 &&
																data?.extra_data?.systolic_pressure < 159 &&
																data?.extra_data?.diastolic_pressure >= 90 &&
																data?.extra_data?.diastolic_pressure < 99
															"
														>
															<mark style="background-color: #ecb563">
																Sistólica:
																{{ data?.extra_data?.systolic_pressure ?? 0 }}
															</mark>
															<br />
															<mark style="background-color: #ecb563">
																Diastólica:
																{{ data?.extra_data?.diastolic_pressure ?? 0 }}
															</mark>
														</p>
														<p
															class="mb-0"
															v-else-if="
																data?.extra_data?.systolic_pressure >= 160 &&
																data?.extra_data?.diastolic_pressure >= 100
															"
														>
															<mark style="background-color: #ec7063">
																Sistólica:
																{{ data?.extra_data?.systolic_pressure ?? 0 }}
															</mark>
															<br />
															<mark style="background-color: #ec7063">
																Diastólica:
																{{ data?.extra_data?.diastolic_pressure ?? 0 }}
															</mark>
														</p>
													</div>
													<div class="ml-3">
														<i
															v-if="
																data?.extra_data?.systolic_pressure < 120 ||
																data?.extra_data?.diastolic_pressure < 80
															"
															style="color: #52be80"
															class="fa-regular fa-face-smile fa-4x"
															aria-hidden="true"
														></i>
														<i
															v-else-if="
																data?.extra_data?.systolic_pressure >= 120 &&
																data?.extra_data?.systolic_pressure < 139 &&
																data?.extra_data?.diastolic_pressure >= 80 &&
																data?.extra_data?.diastolic_pressure < 89
															"
															style="color: #ecb563"
															class="fa-regular fa-face-meh fa-4x"
															aria-hidden="true"
														></i>
														<i
															v-else-if="
																data?.extra_data?.systolic_pressure >= 140 &&
																data?.extra_data?.systolic_pressure < 159 &&
																data?.extra_data?.diastolic_pressure >= 90 &&
																data?.extra_data?.diastolic_pressure < 99
															"
															style="color: #ecb563"
															class="fa-regular fa-face-meh fa-4x"
															aria-hidden="true"
														></i>
														<i
															v-else-if="
																data?.extra_data?.systolic_pressure >= 160 &&
																data?.extra_data?.diastolic_pressure >= 100
															"
															style="color: #ec7063"
															class="fa-regular fa-face-frown fa-4x"
															aria-hidden="true"
														></i>
													</div>
												</div>
											</b-col>
										</b-row>
										<b-row class="mt-4">
											<b-col md="6">
												<div class="training-block d-flex align-items-center">
													<div class="rounded-circle iq-card-icon iq-bg-primary">
														<img
															src="../../../assets/images/page-img/34.png"
															class="img-fluid"
															alt="icon"
														/>
													</div>
													<div class="ml-3">
														<h5>Glucemia</h5>
														<p
															class="mb-0"
															v-if="data && data.glucose && data.glucose.length > 0"
														>
															<mark
																v-if="data.glucose[0].quantity < 100"
																style="background-color: #52be80"
															>
																{{ data.glucose[0].quantity }}
															</mark>
															<mark
																v-else-if="data.glucose[0].quantity <= 125"
																style="background-color: #ecb563"
															>
																{{ data.glucose[0].quantity }}
															</mark>
															<mark v-else style="background-color: #ec7063">
																{{ data.glucose[0].quantity }}
															</mark>
														</p>
														<p class="mb-0" v-else>
															<mark> - </mark>
														</p>
													</div>
													<div
														class="ml-3"
														v-if="data && data.glucose && data.glucose.length > 0"
													>
														<i
															v-if="data.glucose[0].quantity < 100"
															style="color: #52be80"
															class="fa-regular fa-face-smile fa-4x"
															aria-hidden="true"
														></i>
														<i
															v-else-if="data.glucose[0].quantity <= 125"
															style="color: #ecb563"
															class="fa-regular fa-face-meh fa-4x"
															aria-hidden="true"
														></i>
														<i
															v-else
															style="color: #ec7063"
															class="fa-regular fa-face-frown fa-4x"
															aria-hidden="true"
														></i>
													</div>
												</div>
											</b-col>
											<b-col md="6">
												<div class="training-block d-flex align-items-center">
													<div class="rounded-circle iq-card-icon iq-bg-primary">
														<img
															src="../../../assets/images/page-img/35.png"
															class="img-fluid"
															alt="icon"
														/>
													</div>
													<div class="ml-3">
														<h5>Hábitos de Sueño</h5>
														<p class="mb-0">{{ data?.extra_data?.sleep_habits }}</p>
													</div>
												</div>
											</b-col>
										</b-row>
									</div>
								</div>
							</b-col>
						</b-row>
					</template>
				</iq-card>

				<iq-card class-name="iq-card-block" iq-card-stretch iq-card-height>
					<template v-slot:headerTitle>
						<h4 class="card-title">Historial de Glucosa</h4>
					</template>
					<template v-slot:body>
						<ApexChart
							v-if="showChart"
							element="home-chart-06"
							:chartOption="chartData"
						/>
					</template>
				</iq-card>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import IqCard from "../../../components/xray/cards/iq-card";
import { xray } from "../../../config/pluginInit";
import { Toast } from "../../../mixins/toast";
import api from "../../../services/api";

export default {
	name: "DashboardPatient",
	components: { IqCard },
	async mounted() {
		xray.index();
		await this.getData();
		await this.getData2();
	},
	data() {
		return {
			dashboardData: [],
			user: {},
			showChart: false,
			data: {},
			chartData: {
				series: [
					{
						name: "Glucosa",
						data: [],
					},
				],
				chart: {
					height: 350,
					type: "area",
					zoom: {
						enabled: false,
					},
				},
				colors: ["#3892A6"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "smooth",
				},
				xaxis: {
					type: "datetime",
					categories: [],
				},
				tooltip: {
					x: {
						format: "dd/MM/yy",
					},
				},
			},
			patientChart: {
				series: [
					{
						name: "Servings",
						data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31],
					},
				],
				annotations: {
					points: [
						{
							x: "Bananas",
							seriesIndex: 0,
							label: {
								borderColor: "#775DD0",
								offsetY: 0,
								style: {
									color: "#fff",
									background: "#775DD0",
								},
								text: "Bananas are good",
							},
						},
					],
				},
				chart: {
					height: 350,
					type: "bar",
				},
				colors: ["#3892A6"],
				plotOptions: {
					bar: {
						columnWidth: "50%",
						endingShape: "rounded",
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: 2,
				},

				grid: {
					row: {
						colors: ["#fff", "#f2f2f2"],
					},
				},
				xaxis: {
					labels: {
						rotate: -45,
					},
					categories: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
					tickPlacement: "on",
				},
				yaxis: {
					title: {
						text: "Servings",
					},
				},
				fill: {
					type: "gradient",
					gradient: {
						shade: "light",
						type: "horizontal",
						shadeIntensity: 0.25,
						gradientToColors: undefined,
						inverseColors: true,
						opacityFrom: 0.85,
						opacityTo: 0.85,
						stops: [50, 0, 100],
					},
				},
			},
		};
	},
	methods: {
		async getData() {
			this.user = JSON.parse(localStorage.getItem("user"));
			try {
				const { data } = await api.get(`/patients/${this.user.patient_id}`);
				this.data = data.data;

				const date = this.data.glucose.map((item) => item.date);
				const quantity = this.data.glucose.map((item) => item.quantity);

				this.chartData.series[0].data = quantity;
				this.chartData.xaxis.categories = date;
				this.showChart = true;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getData2() {
			try {
				const { data } = await api.get("/dashboard");
				this.dashboardData = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: error.message,
				});
			}
		},
	},
};
</script>
